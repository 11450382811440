<template>
  <div class="container">
    <h2>Edit Vehicles</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button @click="deleteVehicles()" type="button" class="btn btn-danger">
      Delete
    </button>

    <div class="row">
      <div class="col-md-4">
        <label class for="Name">Name</label>
        <input
          class="form-control"
          id="Name"
          name="Name"
          v-model="editingVehicles.Name"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Image">Image</label>
        <div v-if="!editingImage">
          <img
            v-bind:src="getImageSource(editingVehicles)"
            class="previewImage"
          />
          <button @click="editImage()" type="button" class="btn btn-success">
            Update Image
          </button>
        </div>
        <div v-if="editingImage">
          <button @click="updateImage()" type="button" class="btn btn-success">
            Update
          </button>
          <button @click="editImage()" type="button" class="btn btn-warning">
            Cancel
          </button>
          <FileUploader
            @ImageDataChanged="updateImageData"
            @ImageURLDataChanged="updateImageUrl"
          />
        </div>
      </div>
      <div class="col-md-4">
        <label class for="ImageMeta">ImageMeta</label>
        <input
          class="form-control"
          id="ImageMeta"
          name="ImageMeta"
          v-model="editingVehicles.ImageMeta"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="VehicleType">VehicleType</label>
        <input
          class="form-control"
          id="VehicleType"
          name="VehicleType"
          v-model="editingVehicles.VehicleType"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Model">Model</label>
        <input
          class="form-control"
          id="Model"
          name="Model"
          v-model="editingVehicles.Model"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Price">Price</label>
        <input
          class="form-control"
          id="Price"
          name="Price"
          v-model="editingVehicles.Price"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <label class for="PriceNamibia">PriceNamibia</label>
        <input
          class="form-control"
          id="PriceNamibia"
          name="PriceNamibia"
          v-model="editingVehiclePromotionBanners.PriceNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
        <label class for="PriceBotswana">PriceBotswana</label>
        <input
          class="form-control"
          id="PriceBotswana"
          name="PriceBotswana"
          v-model="editingVehiclePromotionBanners.PriceBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
        <label class for="PriceSwaziland">PriceSwaziland</label>
        <input
          class="form-control"
          id="PriceSwaziland"
          name="PriceSwaziland"
          v-model="editingVehiclePromotionBanners.PriceSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Transmission">Transmission</label>
        <input
          class="form-control"
          id="Transmission"
          name="Transmission"
          v-model="editingVehicles.Transmission"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="FuelConsumption">FuelConsumption</label>
        <input
          class="form-control"
          id="FuelConsumption"
          name="FuelConsumption"
          v-model="editingVehicles.FuelConsumption"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="FuelType">FuelType</label>
        <input
          class="form-control"
          id="FuelType"
          name="FuelType"
          v-model="editingVehicles.FuelType"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="BootSpace">BootSpace</label>
        <input
          class="form-control"
          id="BootSpace"
          name="BootSpace"
          v-model="editingVehicles.BootSpace"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="PowerWindows">PowerWindows</label>
        <input
          class="form-control"
          id="PowerWindows"
          name="PowerWindows"
          v-model="editingVehicles.PowerWindows"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Airbags">Airbags</label>
        <input
          class="form-control"
          id="Airbags"
          name="Airbags"
          v-model="editingVehicles.Airbags"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="ABS">ABS</label>
        <input
          class="form-control"
          id="ABS"
          name="ABS"
          v-model="editingVehicles.ABS"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="CentralLocking">CentralLocking</label>
        <input
          class="form-control"
          id="CentralLocking"
          name="CentralLocking"
          v-model="editingVehicles.CentralLocking"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="FogLamps">FogLamps</label>
        <input
          class="form-control"
          id="FogLamps"
          name="FogLamps"
          v-model="editingVehicles.FogLamps"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Displacement">Displacement</label>
        <input
          class="form-control"
          id="Displacement"
          name="Displacement"
          v-model="editingVehicles.Displacement"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="BoreStroke">BoreStroke</label>
        <input
          class="form-control"
          id="BoreStroke"
          name="BoreStroke"
          v-model="editingVehicles.BoreStroke"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="KeyFeatures">KeyFeatures</label>
        <input
          class="form-control"
          id="KeyFeatures"
          name="KeyFeatures"
          v-model="editingVehicles.KeyFeatures"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="BodyType">BodyType</label>
        <input
          class="form-control"
          id="BodyType"
          name="BodyType"
          v-model="editingVehicles.BodyType"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Features">Features</label>
        <input
          class="form-control"
          id="Features"
          name="Features"
          v-model="editingVehicles.Features"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Description">Description</label>
        <input
          class="form-control"
          id="Description"
          name="Description"
          v-model="editingVehicles.Description"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class="" for="CommercialVehicle">CommercialVehicle</label>
        <br />
        <b-form-checkbox
          v-model="editingVehicles.CommercialVehicle"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingVehicles.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditVehicles",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    FileUploader,
  },
  created() {
    this.originalData = JSON.parse(JSON.stringify(this.editingVehicles));
    this.ImageBytes = this.editingVehicles.Image;
  },

  props: ["editingVehicles"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit("closeEditVehicles");
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingVehicles));
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("editVehiclesSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("editVehicles", payload);
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deleteVehicles: function () {
      var payload = {
        data: this.editingVehicles,
        success: (response) => {
          this.$emit("editVehiclesSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteVehicles", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
