<template>
  <div class="container">
    <h2>Add A Vehicles</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <div class="row">
      <div class="col-md-4">
        <label class="" for="Name">Name</label
        ><input
          class="form-control"
          id="Name"
          name="Name"
          v-model="newVehicles.Name"
          placeholder="Name"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class for="Image">Image</label>
        <div v-if="!editingImage">
          <img v-bind:src="getImageSource(newVehicles)" class="previewImage" />
          <button @click="editImage()" type="button" class="btn btn-success">
            Update Image
          </button>
        </div>
        <div v-if="editingImage">
          <button @click="updateImage()" type="button" class="btn btn-success">
            Update
          </button>
          <button @click="editImage()" type="button" class="btn btn-warning">
            Cancel
          </button>
          <FileUploader
            @ImageDataChanged="updateImageData"
            @ImageURLDataChanged="updateImageUrl"
          />
        </div>
      </div>
      <div class="col-md-4">
        <label class="" for="ImageMeta">ImageMeta</label
        ><input
          class="form-control"
          id="ImageMeta"
          name="ImageMeta"
          v-model="newVehicles.ImageMeta"
          placeholder="ImageMeta"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="VehicleType">VehicleType</label
        ><input
          class="form-control"
          id="VehicleType"
          name="VehicleType"
          v-model="newVehicles.VehicleType"
          placeholder="VehicleType"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Model">Model</label
        ><input
          class="form-control"
          id="Model"
          name="Model"
          v-model="newVehicles.Model"
          placeholder="Model"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Price">Price</label
        ><input
          class="form-control"
          id="Price"
          name="Price"
          v-model="newVehicles.Price"
          placeholder="Price"
          tabindex="0"
          type="text"
          value=""
        />
        
           <label class="" for="PriceNamibia">PriceNamibia</label>
           <input
          class="form-control"
          id="PriceNamibia"
          name="PriceNamibia"
          v-model="newVehicles.PriceNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
        <label class="" for="PriceBotswana">PriceBotswana</label>
            <input
          class="form-control"
          id="PriceBotswana"
          name="PriceBotswana"
          v-model="newVehicles.PriceBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
        <label class="" for="PriceSwaziland">PriceSwaziland</label>
              <input
          class="form-control"
          id="PriceSwaziland"
          name="PriceSwaziland"
          v-model="newVehicles.PriceSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Transmission">Transmission</label
        ><input
          class="form-control"
          id="Transmission"
          name="Transmission"
          v-model="newVehicles.Transmission"
          placeholder="Transmission"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="FuelConsumption">FuelConsumption</label
        ><input
          class="form-control"
          id="FuelConsumption"
          name="FuelConsumption"
          v-model="newVehicles.FuelConsumption"
          placeholder="FuelConsumption"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="FuelType">FuelType</label
        ><input
          class="form-control"
          id="FuelType"
          name="FuelType"
          v-model="newVehicles.FuelType"
          placeholder="FuelType"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="BootSpace">BootSpace</label
        ><input
          class="form-control"
          id="BootSpace"
          name="BootSpace"
          v-model="newVehicles.BootSpace"
          placeholder="BootSpace"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="PowerWindows">PowerWindows</label
        ><input
          class="form-control"
          id="PowerWindows"
          name="PowerWindows"
          v-model="newVehicles.PowerWindows"
          placeholder="PowerWindows"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Airbags">Airbags</label
        ><input
          class="form-control"
          id="Airbags"
          name="Airbags"
          v-model="newVehicles.Airbags"
          placeholder="Airbags"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="ABS">ABS</label
        ><input
          class="form-control"
          id="ABS"
          name="ABS"
          v-model="newVehicles.ABS"
          placeholder="ABS"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="CentralLocking">CentralLocking</label
        ><input
          class="form-control"
          id="CentralLocking"
          name="CentralLocking"
          v-model="newVehicles.CentralLocking"
          placeholder="CentralLocking"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="FogLamps">FogLamps</label
        ><input
          class="form-control"
          id="FogLamps"
          name="FogLamps"
          v-model="newVehicles.FogLamps"
          placeholder="FogLamps"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Displacement">Displacement</label
        ><input
          class="form-control"
          id="Displacement"
          name="Displacement"
          v-model="newVehicles.Displacement"
          placeholder="Displacement"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="BoreStroke">BoreStroke</label
        ><input
          class="form-control"
          id="BoreStroke"
          name="BoreStroke"
          v-model="newVehicles.BoreStroke"
          placeholder="BoreStroke"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="KeyFeatures">KeyFeatures</label
        ><input
          class="form-control"
          id="KeyFeatures"
          name="KeyFeatures"
          v-model="newVehicles.KeyFeatures"
          placeholder="KeyFeatures"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="BodyType">BodyType</label
        ><input
          class="form-control"
          id="BodyType"
          name="BodyType"
          v-model="newVehicles.BodyType"
          placeholder="BodyType"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Features">Features</label
        ><input
          class="form-control"
          id="Features"
          name="Features"
          v-model="newVehicles.Features"
          placeholder="Features"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="Description">Description</label
        ><input
          class="form-control"
          id="Description"
          name="Description"
          v-model="newVehicles.Description"
          placeholder="Description"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="CommercialVehicle">CommercialVehicle</label>
        <br />
        <b-form-checkbox
          v-model="newVehicles.CommercialVehicle"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="LastUpdated">LastUpdated</label
        ><input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="newVehicles.LastUpdated"
          placeholder="LastUpdated"
          tabindex="0"
          type="text"
          value=""
        />
        <div class=""><small></small></div>
      </div>
      <br />
    </div>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  name: "addVehicles",
  created: function () {},
  data() {
    return {
      newVehicles: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    FileUploader,
  },
  methods: {
    toggleView: function () {
      this.$emit("closeAddVehicles");
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    save: function () {
      var payload = {
        data: this.newVehicles,
        image: this.uploadedImageData,
        success: (response) => {
          this.$emit("addVehiclesSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("addVehicles", payload);
    },
  },
};
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
