<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addVehiclesModal' hide-footer>
        <addVehicles @closeAddVehicles='toggleAddVehicles()' @addVehiclesSuccess='addVehiclesSuccess()'></addVehicles>
      </b-modal>
      <b-modal ref='editVehiclesModal' hide-footer>
        <editVehicles
          :editingVehicles='currentVehicles'
          @closeEditVehicles='toggleEditVehicles()'
          @editVehiclesSuccess='editVehiclesSuccess()'
        ></editVehicles>
      </b-modal>
      <div class='row'>
        <h2>Vehicles</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddVehicles()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addVehicles from '../../components/addVehicles';
import editVehicles from '../../components/editVehicles';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'ImageMeta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'VehicleType',
                field: 'VehicleType',
                sort: 'asc',
              },
              {
                label: 'Model',
                field: 'Model',
                sort: 'asc',
              },
              {
                label: 'Price',
                field: 'Price',
                sort: 'asc',
              },
              {
                label: 'Transmission',
                field: 'Transmission',
                sort: 'asc',
              },
              {
                label: 'FuelConsumption',
                field: 'FuelConsumption',
                sort: 'asc',
              },
              {
                label: 'FuelType',
                field: 'FuelType',
                sort: 'asc',
              },
              {
                label: 'BootSpace',
                field: 'BootSpace',
                sort: 'asc',
              },
              {
                label: 'PowerWindows',
                field: 'PowerWindows',
                sort: 'asc',
              },
              {
                label: 'Airbags',
                field: 'Airbags',
                sort: 'asc',
              },
              {
                label: 'ABS',
                field: 'ABS',
                sort: 'asc',
              },
              {
                label: 'CentralLocking',
                field: 'CentralLocking',
                sort: 'asc',
              },
              {
                label: 'FogLamps',
                field: 'FogLamps',
                sort: 'asc',
              },
              {
                label: 'Displacement',
                field: 'Displacement',
                sort: 'asc',
              },
              {
                label: 'BoreStroke',
                field: 'BoreStroke',
                sort: 'asc',
              },
              {
                label: 'KeyFeatures',
                field: 'KeyFeatures',
                sort: 'asc',
              },
              {
                label: 'BodyType',
                field: 'BodyType',
                sort: 'asc',
              },
              {
                label: 'Features',
                field: 'Features',
                sort: 'asc',
              },
              {
                label: 'Description',
                field: 'Description',
                sort: 'asc',
              },
              {
                label: 'CommercialVehicle',
                field: 'CommercialVehicle',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addVehiclesOpen: false,
      editVehiclesOpen: false,
      currentVehicles: {}
    };
  },
  components: {
    addVehicles,
    editVehicles,
    Datatable,
  },
  created: function() {
    this.getVehicles();
    this.addVehiclesOpen = false;
    this.editVehiclesOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getVehicles() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getVehicles', payload);
    },
    editItem(Vehicles) {
      this.toggleEditVehicles();
      this.currentVehicles = Vehicles;
    },
    toggleAddVehicles() {
      if(this.addVehiclesOpen)
      {
        this.$refs.addVehiclesModal.hide()
      }
      else{
        this.$refs.addVehiclesModal.show()
      }
      this.addVehiclesOpen = !this.addVehiclesOpen;
    },
    addVehiclesSuccess() {
      this.toggleAddVehicles();
      miniToastr['success']('Vehicles Added', 'Success', 1000, null);
      this.getVehicles();
    },
    toggleEditVehicles() {
      if(this.editVehiclesOpen)
      {
        this.$refs.editVehiclesModal.hide()
      }
      else{
        this.$refs.editVehiclesModal.show()
      }
      this.editVehiclesOpen = !this.editVehiclesOpen;
    },
    editVehiclesSuccess() {
      this.toggleEditVehicles();
      miniToastr['success']('Vehicles Edited', 'Success', 1000, null);
      this.getVehicles();
    }
  }
};
</script>
<style scoped>
.VehiclesThumbnail {
  height: 50px;
}
</style>
